<template>
  <div id="courseLabelPie"></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
]);

export default {
  props:["courseLabel"],
  mounted() {
    var chartDom = document.getElementById("courseLabelPie");
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: 'left',
        textStyle: {
          color: "#00c7ff",
        },
      },
      series: [
        {
          name: "课程数量",
          type: "pie",
          radius: "50%",
          left:'80',
          y:-70,
          // data:this.courseLabel,
          data:[
            {name:'手工劳动',value:'73'},
            {name:'传统文化',value:'23'},
            {name:'安全教育',value:'24'},
            {name:'科学教育',value:'92'},
            {name:'艺术欣赏',value:'34'},
            {name:'国际理解',value:'29'},
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          label: {
            normal: {
              color:'#00c7ff',
              // position: "inner",
              formatter: "{b}({c})",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  },
};
</script>

<style lang="less" scoped>
#courseLabelPie {
  height: 100%;
  width: 100%;
}
</style>
