<template>
  <div id="courseLabel"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { TitleComponent, LegendComponent } from "echarts/components";
import { RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);

export default {
  props:["labelHot"],
  mounted() {
    var chartDom = document.getElementById("courseLabel");
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      tooltip: {
        trigger: "item",
      },
      radar: {
        shape: 'circle',
        // indicator: this.labelHot.title,
        indicator: [
          { name: '传统文化（23）',max: 500},
          { name: '安全教育（24）',max: 500},
          { name: '手工劳动（73）',max: 1000},
          { name: '科学教育（92）',max: 1000},
          { name: '艺术欣赏（34）', max: 500},
          { name: '国际理解（29）',max: 500}],
        axisLine: {
          //(圆内的几条直线)坐标轴轴线相关设置
          lineStyle: {
            color: "#00c7ff", //坐标轴线线的颜色。

            width: 1, //坐标轴线线宽。

            type: "solid", //坐标轴线线的类型。
          },
        },
        splitLine: {
          //(这里是指所有圆环)坐标轴在 grid 区域中的分隔线。

          lineStyle: {
            color: "#00c7ff", //分隔线颜色
            width: 1, //分隔线线宽
          },
        },
        splitArea: {
          //坐标轴在 grid 区域中的分隔区域，默认不显示。

          show: true,

          areaStyle: {
            //分隔区域的样式设置。

            color: ["rgba(250,250,250,0.1)", "rgba(200,200,200,0.1)"], //分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
          },
        },
      },
      series: [
        {
          name: "预约人次",
          type: "radar",
          data: [
            {
              // value: this.labelHot.value,
              value: [165,238,811,953,244,256],
              name: "预约人次",
              itemStyle: {
                //单个拐点标志的样式设置。

                normal: {
                  opacity: 0.8,
                  borderColor: "#FC8653", //拐点的描边颜色
                  borderWidth: 2, //拐点的描边宽度，默认不描边。
                },
                emphasis: {
                  //高亮时的样
                  opacity: 1,
                  borderWidth: 3,
                },
              },
              symbol: "circle", //单个数据标记的图形。

              symbolSize: 3, //单个数据标记的大小
            },
          ],

          itemStyle: {
            //折线拐点标志的样式。

            normal: {
              //普通状态时的样式
              lineStyle: {
                width: 2,
                color: "#FC8653",
              },

              opacity: 0.6,
            },

            emphasis: {
              //高亮时的样式

              lineStyle: {
                width: 3,
              },

              opacity: 1,
            },
          },
        },
      ],
    };
    option && myChart.setOption(option);
  },
};
</script>

<style lang="less" scoped>
#courseLabel {
  height: 100%;
  width: 100%;
}
</style>
