<template>
	<div id="sceneHot"></div>
</template>

<script>
import * as echarts from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([GridComponent, LineChart, CanvasRenderer])
export default {
	props: ['siteTimeHot'],
	mounted() {
		var chartDom = document.getElementById('sceneHot')
		var myChart = echarts.init(chartDom)
		var option

		option = {
			grid: {
				//柱状图位置
				top: 10,
				left: 50,
				right: 40,
				bottom: 10,
				containLabel: true,
			},
			xAxis: {
				type: 'category',
				boundaryGap: false,
				data: this.siteTimeHot.title,
				axisLine: {
					lineStyle: {
						color: '#00c7ff',
						width: 1,
						type: 'dotted',
					},
				},
				axisLabel: {
					show: true,
					rotate: 30,
					textStyle: {
						color: '#00c7ff',
					},
				},
			},
			yAxis: {
				type: 'value',
				axisLine: {
					show: true,
					lineStyle: {
						color: '#00c7ff',
						width: 1,
						type: 'dotted',
					},
				},

				splitLine: {
					show: false,
				},
			},
			tooltip: {
				trigger: 'item',
			},
			series: [
				{
					data: this.siteTimeHot.value,
					type: 'line',
					symbol: 'circle', //折线节点为小圆点
					symbolSize: '7', //折线点大小
					areaStyle: {
						normal: {
							color: '#1890FF',
							opacity: 0.4,
						},
					},
					label: {
						show: true,
						position: 'right',
						valueAnimation: true,
						color: '#00c7ff',
					},
					itemStyle: {
						normal: {
							color: '#1890FF',
							borderColor: '#fff',
							opacity: 1,
						},
					},
					lineStyle: {
						width: 1,
						type: 'solid',
						color: '#1890FF', //折线的颜色
					},
				},
			],
		}
		myChart.clear();
		option && myChart.setOption(option,true);
	},
}
</script>

<style lang="less" scoped>
#sceneHot {
	height: 100%;
	width: 100%;
}
</style>
