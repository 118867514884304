// 引入封装的第一层axios接口
import req from "@/utils/req.js"

// 饼状图\雷达图，获取标签及课程数，热度
export const getCourseLabel_api = (params)=>{
  return req.get("api/resource-count/client/course/tag",params)
}

// 实时考勤统计
export const getRealTimeAttendanceCount_api = (params)=>{
  return req.get("api/resource-count/client/getRealTimeAttendanceCount",params)
}

// 顶部基本数据统计
export const getTopBaseCount_api = (params)=>{
  return req.get("api/resource-count/client/course/getTopBaseCount",params)
}

//顶部在线人数统计
export const getTopOnlineCount_api=(params)=>{
    return req.get("api/resource-count/client/site/user/online-count",params)
}

// 课程热度统计
export const getCourseHot_api = (params)=>{
  return req.get("api/resource-count/client/course/heat",params)
}

// 场景热度统计
export const getSiteHot_api = (params)=>{
  return req.get("api/resource-count/client/site/heat",params)
}

// 场景热度统计(时间轴)
export const getSiteTimeHot_api = (params)=>{
  return req.get("api/resource-count/client/site/time",params)
}