<template>
  <div id="kqBar"></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
]);
export default {
  props: ["kqData"],
  mounted() {
    var chartDom = document.getElementById("kqBar");
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      grid: {
        //柱状图位置
        top: 10,
        right: 73,
        left: 10,
        bottom: 10,
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },
      legend: {
        orient: "vertical",
        right: 4,
        top: 10,
        textStyle: {
          // 图列内容样式
          color: "#00c7ff", // 字体颜色
        },
      },
      yAxis: {
        type: "value",
        minInterval: 1,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#00c7ff",
            width: 1,
            type: "dotted",
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          //格线样式
          show: false,
        },
      },
      xAxis: {
        type: "category",
        data: this.kqData.siteName,
        axisLine: {
          lineStyle: {
            color: "#00c7ff",
            width: 1,
            type: "dotted",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          rotate: -25,
          interval: 0,
          textStyle: {
            color: "#00c7ff",
          },
        },
      },
      series: [
        {
          name: "正常",
          type: "bar",
          stack: "total",
          emphasis: {
            focus: "series",
          },
          data: this.kqData.normal,
          // label: {
          //   show: true,
          //   valueAnimation: true,
          //   color: "#fff",
          // },
          itemStyle: {
            normal: {
              color: "#91CC75",
            },
          },
        },
        {
          name: "迟到",
          type: "bar",
          stack: "total",
          emphasis: {
            focus: "series",
          },
          data: this.kqData.overTime,
          // label: {
          //   show: true,
          //   valueAnimation: true,
          //   color: "#fff",
          // },
          itemStyle: {
            normal: {
              color: "#FAC858",
            },
          },
        },
        {
          name: "未到",
          type: "bar",
          stack: "total",
          emphasis: {
            focus: "series",
          },
          data: this.kqData.truant,
          // label: {
          //   show: true,
          //   valueAnimation: true,
          //   color: "#fff",
          // },
          itemStyle: {
            normal: {
              color: "#5470C6",
            },
          },
        },
      ],
    };
    myChart.clear();
    option && myChart.setOption(option,true);
  },

};
</script>

<style lang="less" scoped>
#kqBar {
  height: 100%;
  width: 100%;
}
</style>
